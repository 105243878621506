var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar',[_c('div',{staticClass:"row w-75"},[_c('div',{staticClass:"col-5"},[_c('h4',{staticClass:"text-light mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Шаблоны сообщений'))+" "),(_vm.is('administrator'))?_c('button',{staticClass:"ml-3 btn btn-primary",on:{"click":function($event){return _vm.showCreateSmsSampleModal()}}},[_vm._v(" "+_vm._s(_vm.$t('Добавить'))+" ")]):_vm._e()])])])]),_c('div',{staticClass:"container-fluid py-4"},[_c('ul',{staticClass:"app-nav-tabs nav nav-tabs mb-3"},[_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({name: 'SmsMessage'})}}},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('СМС сообщения')))])]),_c('li',{staticClass:"nav-item active"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('Шаблоны сообщений')))])])]),_c('FullHeightBlock',{staticClass:"custom-scroll"},[(_vm.sms_samples.length || _vm.is_init)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.sms_samples,"mode":"remote","totalRows":_vm.total,"isLoading":_vm.isLoading,"pagination-options":{
                        enabled: true,
                        perPageDropdown: [10, 20, 50, 100],
                        dropdownAllowAll: false,
                        nextLabel: _vm.$t('туда'),
                        prevLabel: _vm.$t('сюда'),
                        ofLabel: _vm.$t('из'),
                        rowsPerPageLabel: _vm.$t('Записей на страницу'),
                    }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'message')?_c('div',[_c('small',{staticClass:"alert alert-secondary d-block mb-0",attrs:{"data-toggle":"tooltip","title":props.row.message}},[_vm._v(" "+_vm._s(_vm.stringLimit(props.row.message, 50))+" ")])]):(props.column.field === 'comment')?_c('div',[(props.row.comment)?_c('small',{staticClass:"alert alert-primary d-block mb-0",attrs:{"data-toggle":"tooltip","title":props.row.comment}},[_vm._v(" "+_vm._s(_vm.stringLimit(props.row.comment, 50))+" ")]):_vm._e()]):(props.column.field === 'user')?_c('div',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(props.row.user ? props.row.user.name : '-')+" ")]):(props.column.field === 'action')?_c('div',[_c('div',{staticClass:"btn-group btn-block"},[_c('button',{staticClass:"btn btn-sm btn-outline-primary",on:{"click":function($event){return _vm.showUpdateSmsSampleModal(props.row)}}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Редактировать'))+" ")]),_c('button',{staticClass:"btn btn-sm btn-outline-danger",on:{"click":function($event){return _vm.remove(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"}),_vm._v(" "+_vm._s(_vm.$t('Удалить'))+" ")])])]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,713299433)})],1)]):_c('div',{staticClass:"centered"},[_c('div',{staticClass:"display-4 text-black-50 mb-4"},[_vm._v(_vm._s(_vm.$t('Тут пока ничего нет')))])])])],1),_c('CreateSmsSampleModal',{on:{"created":function($event){return _vm.loadSamples()}}}),_c('UpdateSmsSampleModal',{on:{"updated":function($event){return _vm.loadSamples()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }