<template>
    <div class="logo">{{ collapsed ? 'М2' : 'МЕТОДИЧКА 2.0' }}</div>
</template>

<script>
export default {
    name: "Logo",
    props: {
        collapsed: Boolean
    }
}
</script>

<style lang="less" scoped>
.logo {
    background: #007cff;
    height: 70px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 3px;
    font-size: 1rem;
    text-align: center;
    line-height: 70px;
}

</style>
