<template>
    <div>
        <div class="row d-none d-md-flex" style="min-width: 1024px; overflow-x: auto; overflow-y: hidden; height: 100%;">
            <div class="col-4 h-100">
                <h5 class="mb-4">{{ $t('Область') }} <span v-if="regions.length " class="text-black-50"> - {{ regions.length }}</span></h5>
                <FullHeightBlock class="items custom-scroll">
                    <div v-for="(item, index) in regions"
                         @click="currentRegion = item.region_name, currentArea = ''"
                         :key="index"
                         :class="{active : item.region_name === currentRegion}"
                         class="item">
                        {{ item.region_name }}
                        <div class="trademark-icons">
                            <img v-if="item.trademark_id === 1" src="../assets/prosto.png">
                            <img v-if="item.trademark_id === 2" src="../assets/crystal.png">
                        </div>
                        <i v-show="$store.state.shiftKeyPressed" class="fas fa-cog ml-4 text-dark"></i>
                        <i v-show="!$store.state.shiftKeyPressed" class="fas fa-chevron-right"></i>
                    </div>
                </FullHeightBlock>
            </div>
            <div class="col-4 h-100">
                <h5 class="mb-4">{{ $t('Район') }} <span v-if="areas.length " class="text-black-50"> - {{ areas.length }}</span></h5>
                <FullHeightBlock class="items custom-scroll">
                    <div v-for="(item, index) in areas"
                         @click="currentArea = item.area_name"
                         :key="index"
                         :class="{active : item.area_name === currentArea}"
                         class="item">
                        {{ item.area_name }}
                        <div class="trademark-icons">
                            <img v-if="item.trademark_id === 1" src="../assets/prosto.png">
                            <img v-if="item.trademark_id === 2" src="../assets/crystal.png">
                        </div>
                        <i v-show="$store.state.shiftKeyPressed" class="fas fa-cog ml-4 text-dark"></i>
                        <i v-show="!$store.state.shiftKeyPressed" class="fas fa-chevron-right"></i>
                    </div>
                </FullHeightBlock>
            </div>
            <div class="col-4 h-100">
                <h5 class="mb-4">{{ $t('Населенный пункт') }} <span v-if="localitiesList.length " class="text-black-50"> - {{ localitiesList.length }}</span></h5>
                <FullHeightBlock class="items custom-scroll">
                    <div v-for="(item, index) in localitiesList"
                         @click="$router.push({name: 'Locality', params: {locality_id: item.id} })"
                         :key="index"
                         class="item">
                        {{ item.locality_name }}
                        <div class="trademark-icons">
                            <img v-if="item.trademark_id === 1" src="../assets/prosto.png">
                            <img v-if="item.trademark_id === 2" src="../assets/crystal.png">
                        </div>
                        <i v-show="$store.state.shiftKeyPressed" class="fas fa-cog ml-4 text-dark"></i>
                        <i v-show="!$store.state.shiftKeyPressed" class="fas fa-chevron-right"></i>
                    </div>
                </FullHeightBlock>
            </div>
        </div>
        <div class="row d-md-none" style="overflow-x: auto; overflow-y: hidden; height: 100%;">
            <div class="col-12 h-100 pb-5" v-if="!currentRegion && !currentArea">
                <h5 class="mb-4">{{ $t('Область') }} <span v-if="regions.length " class="text-black-50"> - {{ regions.length }}</span></h5>
                <vue-custom-scrollbar class="items" :settings="scrollSettings">
                    <div v-for="(item, index) in regions"
                         @click="currentRegion = item.region_name, currentArea = ''"
                         :key="index"
                         :class="{active : item.region_name === currentRegion}"
                         class="item">
                        {{ item.region_name }}
                        <div class="trademark-icons">
                            <img v-if="item.trademark_id === 1" src="../assets/prosto.png">
                            <img v-if="item.trademark_id === 2" src="../assets/crystal.png">
                        </div>
                        <i v-show="$store.state.shiftKeyPressed" class="fas fa-cog ml-4 text-dark"></i>
                        <i v-show="!$store.state.shiftKeyPressed" class="fas fa-chevron-right"></i>
                    </div>
                </vue-custom-scrollbar>
            </div>
            <div class="col-12 h-100 pb-5" v-if="currentRegion && !currentArea">
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <h5 class="mb-4">{{ $t('Район') }} <span v-if="areas.length " class="text-black-50"> - {{ areas.length }}</span></h5>
                    <button @click="currentRegion = ''" class="btn btn-sm btn-secondary">{{ $t('Назад') }}</button>
                </div>
                <vue-custom-scrollbar class="items" :settings="scrollSettings">
                    <div v-for="(item, index) in areas"
                         @click="currentArea = item.area_name"
                         :key="index"
                         :class="{active : item.area_name === currentArea}"
                         class="item">
                        {{ item.area_name }}
                        <div class="trademark-icons">
                            <img v-if="item.trademark_id === 1" src="../assets/prosto.png">
                            <img v-if="item.trademark_id === 2" src="../assets/crystal.png">
                        </div>
                        <i v-show="$store.state.shiftKeyPressed" class="fas fa-cog ml-4 text-dark"></i>
                        <i v-show="!$store.state.shiftKeyPressed" class="fas fa-chevron-right"></i>
                    </div>
                </vue-custom-scrollbar>
            </div>
            <div class="col-12 h-100 pb-5" v-if="currentRegion && currentArea">
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <h5 class="mb-4">{{ $t('Населенный пункт') }} <span v-if="localitiesList.length " class="text-black-50"> - {{ localitiesList.length }}</span></h5>
                    <button @click="currentArea = ''" class="btn btn-sm btn-secondary">{{ $t('Назад') }}</button>
                </div>
                <vue-custom-scrollbar class="items border-right-0" :settings="scrollSettings">
                    <div v-for="(item, index) in localitiesList"
                         @click="$router.push({name: 'Locality', params: {locality_id: item.id} })"
                         :key="index"
                         class="item">
                        {{ item.locality_name }}
                        <div class="trademark-icons">
                            <img v-if="item.trademark_id === 1" src="../assets/prosto.png">
                            <img v-if="item.trademark_id === 2" src="../assets/crystal.png">
                        </div>
                        <i v-show="$store.state.shiftKeyPressed" class="fas fa-cog ml-4 text-dark"></i>
                        <i v-show="!$store.state.shiftKeyPressed" class="fas fa-chevron-right"></i>
                    </div>
                </vue-custom-scrollbar>
            </div>
        </div>
    </div>

</template>

<script>

import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import FullHeightBlock from "@/components/FullHeightBlock";
import {chain} from "lodash";

export const trademarksMap = [{
    id: 1,
    name: "Prosto",
    icon: require("../assets/prosto.png")
}, {
    id: 2,
    name: "Crystal Telecom",
    icon: require("../assets/crystal.png")
}];

export default {
    name: "LocalitiesPicker2",
    components: {
        vueCustomScrollbar,
        FullHeightBlock
    },
    props: {
        localities: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            currentRegion: "",
            currentArea: "",
            scrollSettings: {
                suppressScrollY: false,
                suppressScrollX: false,
                wheelPropagation: false
            },
            trademarksMap: trademarksMap
        }
    },
    computed: {
        regions() {
            return chain(this.localities)
                .uniqBy('region_name')
                .sortBy('region_name')
                .value();
        },
        areas() {
            return chain(this.localities)
                .filter(item => item.region_name === this.currentRegion)
                .uniqBy("area_name")
                .sortBy("area_name")
                .value()
        },
        localitiesList() {
            return chain(this.localities)
                .filter(item => item.region_name === this.currentRegion && item.area_name === this.currentArea)
                .sortBy("locality_name")
                .value()
        }
    }
}
</script>

<style lang="less" scoped>

.items {
    //height: calc(100vh - 250px);
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid #d0d0d0;
    padding-right: 2rem;

    .item {
        position: relative;
        cursor: pointer;
        width: 100%;
        height: 48px;
        padding: .7rem 1rem;
        background: #fff;
        margin-bottom: .5rem;
        border-radius: .2rem;

        &:hover {
            background: #f5f5f5;
        }

        &.active {
            background: #007BFF;
            color: #fff;

            i {
                color: #fff;
            }

            img {
                background: #fff;
                border-radius: .5rem;
            }
        }

        i {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            color: #D0D0D0;
        }

        img {
            padding: .2rem;
            width: 28px;
            height: 28px;
            margin-right: .5rem;
        }
    }
}

.trademark-icons {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);

    img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        border-radius: .5rem;
        margin: 0 .5rem;
        opacity: .9;
    }

}

</style>
