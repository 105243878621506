import AbstractService from "@/services/AbstractService";

export default class CachedLocalitiesService extends AbstractService {

    constructor() {
        super();
    }

    fetch() {
        return this.axios.get(`/cached_localities`);
    }
}
