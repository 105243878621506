<template>
    <a href="javascript:void(0)"
       data-toggle="popover"
       data-html="true"
       :title="title"
       :data-content="content">
        <slot></slot>
    </a>
</template>

<script>
import {EventBus, Events} from "@/EventBus";
import {uniqueId} from "lodash";

export default {
    name: "TariffCardTvPopover",
    props: {
        tariff: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            uniq_id: uniqueId("button_"),
            tv_bundles: {
                100: this.$t('Информационные, региональные и познавательные каналы'),
                126: this.$t('(Национальные, рейтинговые, информационные и познавательные каналы) + кинозал на 2000 фильмов'),
                128: this.$t('Топовые каналы (Национальные, спортивные, информационные и познавательные) + кинозал на 20 000 фильмов'),
                132: this.$t('Топовые каналы (Национальные, спортивные, информационные и познавательные) + кинозал на 20 000 фильмов'),
            }
        }
    },
    methods: {
        openModal() {
            EventBus.$emit(Events.TV_BUNDLE_CHANNELS_MODAL_OPEN, this.tariff?.iptv?.service_id);
        }
    },
    computed: {
        title() {
            return this.tariff?.iptv?.name;
        },
        content() {
            if (this.tv_bundles[this.iptvServiceId]) {
                return this.tv_bundles[this.iptvServiceId] + this.buttonChannels;
            }

            return `${this.$t('Нет данных для')} service_id: ${this.iptvServiceId}`;
        },
        buttonChannels() {
            return `<div id="${this.uniq_id}" class="btn btn-primary btn-sm btn-block mt-2">${this.$t('Список каналов')}</div>`
        },
        iptvServiceId() {
            return (this.tariff?.iptv?.service_id || 0) * 1;
        }
    },
    beforeDestroy() {
        $(document).off("click", `#${this.uniq_id}`, this.openModal);
    },
    mounted() {
        $(document).on("click", `#${this.uniq_id}`, this.openModal);
    }
}
</script>

<style scoped>

</style>
