<template>
    <div>
        <Navbar>
            <div class="row">
                <div class="col-12">
                    <h4 class="text-light mb-0">{{ $t('Конкуренты') }}</h4>
                </div>
            </div>
        </Navbar>
        <FullHeightBlock class="custom-scroll">
            <div class="container-fluid pt-4">
                <div v-if="opponents.length" class="row">
                    <div class="col-12">
                        <vue-good-table
                            :columns="columns"
                            :rows="opponents"
                            mode="remote"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="total_opponents"
                            :isLoading.sync="isLoading"
                            :pagination-options="{
                            enabled: true,
                            perPageDropdown: [7, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'locality'">
                                    <small class="d-block font-weight-bold text-primary">{{
                                            localityData(props.row.locality_id).locality_name
                                        }}</small>
                                    <small class="d-block text-black-50">{{
                                            localityData(props.row.locality_id).area_name
                                        }}</small>
                                    <small class="d-block text-black-50 font-weight-bold">{{
                                            localityData(props.row.locality_id).region_name
                                        }}</small>
                                </div>
                                <div v-else-if="props.column.field === 'group'">
                                    <small class="">{{ $store.state.groupsList[props.row.group_id] }}</small>
                                </div>
                                <div v-else-if="props.column.field === 'text'">
                                    <small v-html="convertLinks(props.row.text) || prepText" class=""></small>
                                </div>
                                <div v-else-if="props.column.field === 'date'">
                                    <small class="">{{ props.row.created_at | formatDate }}</small>
                                </div>
                                <div v-else-if="props.column.field === 'action'">
                                    <div class="btn-group btn-block">
                                        <button
                                            @click="$router.push({name: 'Locality', params: {locality_id : props.row.locality_id}})"
                                            class="btn btn-sm btn-primary">
                                            {{ $t('На страницу НП') }}
                                            <i class="fas fa-arrow-right fa-fw"></i>
                                        </button>
                                    </div>
                                </div>
                                <div v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
                <div v-else class="centered">
                    <div class="display-4 text-black-50">{{ $t('Тут пока ничего нет') }}</div>
                </div>
            </div>
        </FullHeightBlock>
    </div>
</template>

<script>

import AddOrUpdateUserModal from "@/modals/UpdateUserModal";
import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import OpponentService from "@/services/OpponentService";
import TimeAgo from "javascript-time-ago";
import Helper from "@/Helper";
import ru from "javascript-time-ago/locale/ru";
import linkifyHtml from 'linkify-html';
import FullHeightBlock from "@/components/FullHeightBlock.vue";

const Opponent = new OpponentService();

export default {
    name: 'Opponents',
    components: {
        FullHeightBlock,
        Navbar,
        VueGoodTable,
        AddOrUpdateUserModal
    },
    filters: {
        formatDate(date) {
            const timeAgo = new TimeAgo('ru')
            return timeAgo.format(new Date(date));
        },
        prepText(text) {
            return Helper.stringLimit(text, 100)
        },
    },
    data() {
        return {
            opponents: [],
            total_opponents: 0,
            limit: 7,
            offset: 0,
            isLoading: false,
            columns: [
                {
                    label: this.$tc('Населенный пункт'),
                    field: 'locality',
                    sortable: false,
                },
                {
                    label: this.$tc('Группа'),
                    field: 'group',
                    sortable: false,
                },
                {
                    label: this.$tc('Название'),
                    field: 'title',
                    sortable: false,
                },
                {
                    label: this.$tc('Текст'),
                    field: 'text',
                    sortable: false,
                },
                {
                    label: this.$tc('Дата'),
                    field: 'date',
                    sortable: false,
                },
                {
                    label: this.$tc('Действия'),
                    field: 'action',
                    width: '250px',
                    sortable: false,
                },
            ],
        }
    },
    methods: {
        localityData(locality_id) {
            return this.$store.state?.localities?.find(item => Number(item.id) === Number(locality_id)) || {};
        },
        async onPageChange(e) {
            this.offset = (e.currentPage * this.limit) - this.limit;
            await this.loadOpponents(this.limit, this.offset);
        },
        async onPerPageChange(e) {
            this.limit = e.currentPerPage;
            await this.loadOpponents(this.limit, this.offset);
        },
        async loadOpponents(limit, offset) {
            this.isLoading = true;
            const {code, body} = await Opponent.fetch(null, limit, offset);
            this.isLoading = false;

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.opponents = body.content;
                this.total_opponents = body.total
            }
        },
        convertLinks(e) {
            const text = Helper.stringLimit(
                Helper.nl2br(e),
                320
            );

            return linkifyHtml(text, {
                target: "_blank"
            });
        }
    },
    created() {
        TimeAgo.addLocale(ru);
    },
    async mounted() {
        document.title = this.$tc("Конкуренты");

        this.$preloader.show();
        await this.loadOpponents(this.limit, this.offset);
        this.$preloader.hide();
        this.convertLinks();
    }
}
</script>
