var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h4',{staticClass:"text-light mb-0"},[_vm._v(_vm._s(_vm.$t('Типы пользователей'))+" "),_c('button',{staticClass:"ml-3 btn btn-primary",on:{"click":function($event){return _vm.create()}}},[_vm._v(_vm._s(_vm.$t('Добавить')))])])])])]),_c('FullHeightBlock',{staticClass:"custom-scroll"},[_c('div',{staticClass:"container-fluid pt-4"},[(_vm.roles.length)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.roles,"pagination-options":{
                        enabled: true,
                        perPage: 10,
                        perPageDropdown: [5, 10, 20, 50, 100],
                        dropdownAllowAll: false,
                        nextLabel: _vm.$t('туда'),
                        prevLabel: _vm.$t('сюда'),
                        ofLabel: _vm.$t('из'),
                        rowsPerPageLabel: _vm.$t('Записей на страницу'),
                    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('div',{staticClass:"font-weight-bold text-black-50"},[_vm._v(" "+_vm._s(props.row.name)+" ")]):(props.column.field === 'slug')?_c('div',[_vm._v(" "+_vm._s(props.row.slug)+" ")]):(props.column.field === 'users_count')?_c('div',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(props.row.users_count)+" ")]):(props.column.field === 'action')?_c('div',[_c('div',{staticClass:"btn-group btn-block"},[_c('button',{staticClass:"btn btn-sm btn-outline-primary",on:{"click":function($event){return _vm.update(props.row)}}},[_c('i',{staticClass:"fas fa-user-edit fa-fw"}),_vm._v(" "+_vm._s(_vm.$t('Править'))+" ")]),(props.row.users_count === 0)?_c('button',{staticClass:"btn btn-sm btn-outline-danger",on:{"click":function($event){return _vm.destroy(props.row.id)}}},[_c('i',{staticClass:"fas fa-user-times fa-fw"}),_vm._v(" "+_vm._s(_vm.$t('Удалить'))+" ")]):_vm._e()])]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,1842265673)})],1)]):_c('div',{staticClass:"centered"},[_c('div',{staticClass:"display-4 text-black-50"},[_vm._v("Тут пока ничего нет")])])])]),_c('CreateUserRoleModal',{on:{"created":function($event){return _vm.fetch(false)}}}),_c('UpdateUserRoleModal',{on:{"updated":function($event){return _vm.fetch(false)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }