<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content" style="height: 90vh;">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Слепок НП') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="background: #E5E5E5;">
                    <div v-if="loading" class="text-center py-5 my-5">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="locality_snapshot">
                            <div class="alert alert-success p-3 mb-4">
                                <div class="font-weight-bold">
                                    {{ $t('Показан слепок НП на момент создания (редактирования) текущей заметки') }}.
                                </div>
                                {{ $t('Слепок создан') }}: {{ note.updated_at }}
                            </div>

                            <LocalityTariffsTabs :current-group="currentGroup"
                                                 :tariffs-groups="tariffsGroups"
                                                 :trademark_id="locality_snapshot.trademark_id"
                                                 @change="currentGroup = $event"/>

                            <TariffCard v-for="(tariff, index) in currentGroupTariffs"
                                        :tariff="tariff"
                                        :key="tariff.id"
                                        hide-buttons>
                            </TariffCard>

                        </div>
                        <div v-else class="alert alert-danger font-weight-bold p-3 text-center">
                            {{ $t('Нет слепка НП для этой заметки') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import TariffCard from "@/components/TariffCard";
import LocalitySnapshotService from "@/services/LocalitySnapshotService";
import {uniq} from "lodash";
import Helper from "@/Helper";
import LocalityTariffsTabs from "@/components/LocalityTariffsTabs";

const LocalitySnapshot = new LocalitySnapshotService()

export default {
    name: "LocalitySnapshotModal",
    components: {
        TariffCard,
        LocalityTariffsTabs
    },
    data() {
        return {
            visible: false,
            loading: false,
            locality_snapshot: null,
            note: null,
            currentGroup: null,
            showMode: "active"
        }
    },
    methods: {
        async getLocalitySnapshot(note) {
            this.loading = true;

            const {body} = await LocalitySnapshot.fetch({
                note_id: note.id
            });

            if (body?.content?.length > 0) {
                this.locality_snapshot = JSON.parse(body.content[0].locality_snapshot);
            }

            this.loading = false;
        },
        closeModal() {
            Object.assign(this.$data, this.$options.data());
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        onHideBsModal() {
            this.visible = false;
        },
        async onShowModal(note) {
            this.visible = true;
            this.note = note;
            await this.getLocalitySnapshot(note);

            this.currentGroup = this.tariffsGroups[0];
        },
    },
    computed: {
        tariffsList() {
            return this.locality_snapshot.tariffs?.filter(item => {
                if (this.showMode === "active") {
                    return !(item?.hidden || item?.deleted || item?.duplicate)
                        && Helper.isActiveDate(item?.date_start, item?.date_stop);
                } else {
                    return !item?.duplicate
                        && Helper.isActiveDate(item?.date_start, item?.date_stop);
                }
            });
        },
        currentGroupTariffs() {
            return this.tariffsList?.filter(item => {
                return this.currentGroup === item.parent_group_name;
            }).sort((a, b) => a.sort - b.sort);
        },
        tariffsGroups() {
            return uniq(this.tariffsList.map(item => item.parent_group_name));
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        },
    },
    mounted() {
        EventBus.$on(Events.LOCALITY_SNAPSHOT_MODAL_OPEN, this.onShowModal);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.LOCALITY_SNAPSHOT_MODAL_OPEN, this.onShowModal);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    }
}
</script>

<style scoped>

</style>
