<template>
    <transition name="slide-fade">
        <div v-if="visible" v-click-outside="hide" class="locality-tariffs-notes">
            <button @click="hide" class="btn btn-danger">
                <i class="fas fa-times"></i>
            </button>
            <FullHeightBlock class="custom-scroll pr-2">
                <div v-for="tariff in tariffWithNotes" class="locality-tariffs-notes__item">
                    <div class="font-weight-bold w-100">{{ tariff.name }} <span class="text-muted float-right"> {{ tariff.uniq_id }}</span></div>
                    <div class="font-weight-bold text-primary mb-3">{{ tariff.group_name }}</div>
                    <TariffNote v-for="note in tariff.notes"
                                :note="note"
                                :tariff="tariff"
                                :controls="true"
                                class="mb-3"
                                :key="Math.random()">
                    </TariffNote>
                </div>
            </FullHeightBlock>
        </div>
    </transition>
</template>

<script>

import ClickOutside from 'vue-click-outside';
import TariffNote from "@/components/TariffNote";
import FullHeightBlock from "@/components/FullHeightBlock";

export default {
    name: "LocalityTariffsNotesSidebar",
    components: {
        TariffNote,
        FullHeightBlock
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        tariffs: {
            type: Array,
            required: true
        }
    },
    methods: {
        hide() {
            this.visible && this.$emit('close');
        }
    },
    computed: {
        tariffWithNotes() {
            return this.tariffs.filter(item => item.notes.length)
        }
    },
    directives: {
        ClickOutside
    }
}
</script>

<style lang="less" scoped>

.locality-tariffs-notes {
    position: fixed;
    top: 0;
    right: 0;
    width: 500px;
    background: #e5e5e5;
    height: 100vh;
    overflow: hidden;
    z-index: 100;
    padding: 60px 1rem 1rem;
    color: #000;
    box-shadow: -6px 0px 6px 1px rgba(0, 0, 0, 0.20);

    @media (max-width: 520px) {
        width: 100%;
    }

    .btn {
        position: absolute;
        top: .5rem;
        left: 1rem;
    }
}

.locality-tariffs-notes__item {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: .3rem;
    padding: 1rem;
    margin-bottom: 1rem;
}


.slide-fade-enter-active {
    transition: all .2s;
}

.slide-fade-leave-active {
    transition: all .2s;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(100%);
}

</style>
