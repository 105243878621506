<template>
    <div v-observe-visibility="{
                callback: this.visibilityMessageChanged,
                intersection: {
                    threshold: 0.7
                }
            }"
         class="message" :class="{'my-message' : message.user.id === $store.state.user.id}">
        <div class="message__head">
            <strong>{{ message.user ? message.user.name : $t('Пользователь удален') }}</strong>
            <span data-toggle="tooltip" :key="dateTooltip" :title="dateTooltip">
                {{ formatDate(message.created_at) }}
            </span>
        </div>
        <div class="message__body" v-html="sanitizeMessage(message)"></div>
        <i v-if="(message.user.id === $store.state.user.id) && !message.deleted_at" @click="updateMessage()"
           class="message-icon far fa-edit mr-4"></i>
        <template v-if="(message.user.id === $store.state.user.id) && !message.deleted_at">
            <div v-if="deleting" class="message-icon spinner-border spinner-border-sm text-primary" role="status">
                <span class="sr-only">{{ $t('Загрузка...') }}</span>
            </div>
            <i v-else @click="deleteMessage()" class="message-icon far fa-trash-alt"></i>
        </template>
    </div>
</template>

<script>

import {ObserveVisibility} from 'vue-observe-visibility';
import Helper from "@/Helper";
import TimeAgo from 'javascript-time-ago';
import ru from 'javascript-time-ago/locale/ru'
import MessageService from "@/services/MessageService";
import {EventBus, Events} from "@/EventBus";

const Message = new MessageService()

export default {
    name: "CommentsMessage",
    directives: {
        ObserveVisibility
    },
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            deleting: false
        }
    },
    methods: {
        // Удаляет весь html кроме ссылок, заменяет переносы строк на br
        sanitizeMessage(message) {
            if (message?.deleted_at) {
                return `<i class='text-muted'>Комментарий удален ${this.formatDate(message?.deleted_at)}</i>`;
            }

            let out = message.message
                .replace(/<a/g, "[a")
                .replace(/<\/a>/g, "[\/a]");

            return Helper.stripTags(out)
                .replace(/\[a/g, "<a")
                .replace(/\[\/a]/g, "<\/a>")
                .replace(/\n/g, '<br>');
        },
        formatDate(date) {
            const timeAgo = new TimeAgo('ru')
            return timeAgo.format(new Date(date));
        },
        visibilityMessageChanged(visible) {
            if (visible
                && this.message?.read_at?.read_at === null
                && this.message?.user?.id * 1 !== this.$store.state?.user?.id * 1) {
                this.$emit("read", this.message);
            }
        },
        async deleteMessage() {
            if (!confirm(this.$tc('Удалить комментарий?'))) return;

            this.deleting = true;
            const {code, body} = await Message.delete(this.message.id)
            this.deleting = false;

            if (code === 200) {
                this.$emit("deleted", body);
            } else {
                this.$toast.error(this.$tc('Ошибка удаления!'));
            }
        },
        updateMessage() {
            EventBus.$emit(Events.UPDATE_MESSAGE_MODAL_OPEN, this.message);
        },
    },
    computed: {
        dateTooltip() {
            if (this.message.updated_at !== this.message.created_at) {
                return `${this.$t('Создано')} ${this.message.created_at}<br>${this.$t('Обновлено')} ${this.message.updated_at}`;
            }

            return this.message.created_at;
        }
    },
    created() {
        TimeAgo.addLocale(ru);
    }
}
</script>

<style lang="less" scoped>

.message {
    width: 90%;
    position: relative;

    .message-icon {
        display: none;
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: .8rem;
        cursor: pointer;
        color: #a1a1a1;

        &:hover {
            color: #000;
        }
    }

    &:hover {
        .message-icon {
            display: block;
        }
    }

    &__head {
        margin-bottom: .3rem;

        strong {
            font-size: .8rem;
        }

        span {
            font-size: .7rem;
            font-weight: bold;
            padding-left: 1rem;
            color: #979797;
        }
    }

    &__body {
        background: #fff;
        border-radius: 0 1rem 1rem 1rem;
        padding: 1rem;
        margin-bottom: 1.5rem;
        font-size: .9rem;
    }
}

.my-message {
    margin-left: 10%;

    .message {
        &__head {
            text-align: right;
        }

        &__body {
            background: #c0dcf3;
            border-radius: 1rem 0 1rem 1rem;
        }
    }
}


</style>
