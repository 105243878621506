<template>
    <div class="w-100 pt-4 mr-0">
        <FullHeightBlock :offsetBottom="is('administrator') ? 160 : 15" class="custom-scroll">
            <div v-for="(item, index) in groupOpponents"
                 :key="index"
                 class="alert-primary alert mb-3 mr-3 position-relative opponent">
                <strong>{{ item.title }}</strong>
                <p v-html="convertLinks(item.text)"></p>

                <i v-if="is('administrator')" @click="openUpdateOpponentModal(item)"
                   class="item-icon far fa-edit mr-4"></i>
                <div v-if="deleting" class="item-icon spinner-border spinner-border-sm text-primary" role="status">
                    <span class="sr-only">{{ $t('Загрузка...') }}</span>
                </div>
                <i v-else-if="!deleting && is('administrator')"
                   @click="deleteOpponent(item.id)"
                   class="far fa-trash-alt item-icon"></i>
            </div>
        </FullHeightBlock>
        <div v-if="is('administrator')" class="row pt-2 px-1">
            <div class="col-12 mb-4">
                <input v-model="title" class="form-control" type="text" :placeholder="$t('Название конкурента')">
            </div>
            <div class="col-9">
                <textarea v-model="text" class="form-control" :placeholder="$t('Информация о конкуренте')"></textarea>
            </div>
            <div class="col-3 d-flex">
                <button @click="addOpponent" :disabled="processing || !text.length" class="btn btn-primary btn-block">
                    <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status"></div>
                    <span v-else>{{ $t('Сохранить') }}</span>
                </button>
            </div>
        </div>
        <UpdateOpponentModal @updated="updateOpponent"></UpdateOpponentModal>
    </div>
</template>

<script>

import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import OpponentService from "@/services/OpponentService";
import UpdateOpponentModal from "@/modals/UpdateOpponentModal";
import {EventBus, Events} from "@/EventBus";
import FullHeightBlock from "@/components/FullHeightBlock";
import Helper from "@/Helper";
import linkifyHtml from 'linkify-html';

const Opponent = new OpponentService()

export default {
    name: "Opponents",
    components: {
        vueCustomScrollbar,
        UpdateOpponentModal,
        FullHeightBlock
    },
    props: {
        group_id: {
            type: [String, Number],
            required: true
        },
        locality_id: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            opponents: [],
            title: "",
            text: "",
            processing: false,
            deleting: false,
            scrollSettings: {
                suppressScrollY: false,
                suppressScrollX: false,
                wheelPropagation: false
            },
        }
    },
    methods: {
        nl2br: Helper.nl2br,
        openUpdateOpponentModal(opponent) {
            EventBus.$emit(Events.UPDATE_OPPONENT_MODAL_OPEN, opponent);
        },
        updateOpponent(opponent) {
            this.opponents = this.opponents?.map(item => {
                return opponent.id === item.id ? opponent : item;
            });
        },
        async addOpponent() {
            this.processing = true;

            const {code, body} = await Opponent.create({
                locality_id: this.locality_id,
                group_id: this.group_id,
                title: this.title,
                text: this.text
            });

            this.processing = false;

            if (code === 201) {
                this.opponents.push(body);
                this.title = "";
                this.text = "";
            }
        },
        async deleteOpponent(id) {
            if (!confirm(this.$tc('Удалить запись?'))) return;

            this.deleting = true;
            const {code, body} = await Opponent.delete(id);
            this.deleting = false;

            if (code === 200) {
                this.opponents = this.opponents?.filter(item => item.id !== body.id);
            }
        },
        async loadOpponents() {
            const {code, body} = await Opponent.fetch(this.locality_id);

            if (code === 200) {
                this.opponents = body.content.reverse();
            }
        },
        convertLinks(e) {
            const text = Helper.stringLimit(
                Helper.nl2br(e),
                320
            );

            return linkifyHtml(text, {
                target: "_blank"
            });
        }
    },
    computed: {
        groupOpponents() {
            return this.opponents?.filter(item => Number(item.group_id) === Number(this.group_id));
        }
    },
    watch: {
        groupOpponents(val) {
            this.$emit("init", val.length);
        },
    },
    async mounted() {
        await this.loadOpponents();
        this.convertLinks();
    }
}
</script>

<style lang="less" scoped>
.opponent {
    .item-icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: .8rem;
        cursor: pointer;
        display: none;
    }

    &:hover {
        .item-icon {
            display: block;
        }
    }
}
</style>
