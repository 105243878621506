<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Список НП') }} ({{ locality_id.length }})
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-12" style="height: 500px; overflow-y: auto;">
                        <div v-show="localitiesList.length > 10" class="input-group mb-4">
                            <input v-model="searchQuery" type="text" class="form-control" :placeholder="$t('Поиск')">
                            <div v-show="searchQuery.length > 0" class="input-group-append">
                                <button @click="searchQuery = ''" class="btn btn-danger">{{ $t('очистить') }}</button>
                            </div>
                        </div>
                        <div class="list-group">
                            <button v-for="(item, index) in searchedLocalities"
                                    :key="index"
                                    @click="toLocality(item)"
                                    class="list-group-item list-group-item-action">
                                <img class="mr-3 border rounded"
                                     style="width: 20px; height: 20px; object-fit: contain;"
                                     :src="trademark(item).icon"/>
                                {{ item.region_name }} {{ $t('обл') }}. / {{ item.area_name }} {{ $t('р-н') }}. /
                                <strong>{{ item.locality_name }}</strong>
                                <i class="fas fa-chevron-right float-right text-primary mt-1"></i>
                            </button>
                        </div>
                        <div v-if="!localitiesList.length" class="text-center centered text-muted display-4">
                            {{ $t('Ничего не найдено') }} (·.·)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Helper from "@/Helper";
import {EventBus, Events} from "@/EventBus";
import {mapState} from "vuex";
import MockFactory from "@/classess/MockFactory";

export default {
    name: "ShowLocalitiesModal",
    data() {
        return {
            visible: false,
            processing: false,
            locality_id: [],
            searchQuery: ''
        }
    },
    methods: {
        closeModal() {
            Object.assign(this.$data, this.$options.data.apply(this));
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        trademark(locality) {
            return this.trademarks.find(item => Number(item.id) === Number(locality.trademark_id)) || {};
        },
        toLocality(locality) {
            this.visible = false;
            this.$router.push({name: 'Locality', params: {locality_id: locality.id}})
        },
        onHideBsModal() {
            this.visible = false;
        },
        onShowModal(locality_id) {
            this.visible = true;
            this.locality_id = locality_id
        },
    },
    computed: {
        ...mapState({
            localities: state => state?.localities,
            trademarks: state => state?.trademarks,
        }),
        localitiesList() {
            return this.locality_id.map(id => {
                return this.localities.find(item => item.id == id) || MockFactory.getLocalityObject()
            }).sort((a, b) => a.locality_name.localeCompare(b.locality_name));
        },
        searchedLocalities() {
            const query = Helper.keyboardConverter(this.searchQuery.toLowerCase());
            const filtered = this.localitiesList?.filter(item => {
                return item.locality_search_name.toLowerCase().includes(query);
            });

            return filtered.sort((a, b) => a.locality_name?.localeCompare(b.locality_name))
                .slice(0, query.length ? 15 : 1000);
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.SHOW_LOCALITIES_MODAL, this.onShowModal);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.SHOW_LOCALITIES_MODAL, this.onShowModal);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    },
}
</script>

<style lang="less" scoped>

</style>
