import AbstractService from "@/services/AbstractService";
import store from "@/store";

export default class TariffNoteService extends AbstractService {

    constructor() {
        super();
    }

    get(id) {
        return this.axios.get(`/tariff_note/${id}`);
    }

    fetch({limit = 10, offset = 0, tariff_id = null, search_query = "", locality_uk = [], area_uk = [], region_uk = []}) {
        return this.axios.get(`/tariff_note`, {
            params: {
                limit,
                offset,
                tariff_id,
                search_query,
                locality_uk,
                area_uk,
                region_uk,
                department_id: store.getters.getCurrentDepartmentId,
            }
        });
    }

    create({note, title, tariff_id, trademark_id, locality_id, color}) {
        return this.axios.post(`/tariff_note`, {note, title, tariff_id, trademark_id, locality_id, color});
    }

    update({id, note, title, color}) {
        return this.axios.put(`/tariff_note/${id}`, {note, title, color});
    }

    delete(id) {
        return this.axios.delete(`/tariff_note/${id}`);
    }
}
