<template>
    <div class="row" style="min-width: 1024px; overflow-x: auto; overflow-y: hidden;">
        <h5 class="mb-4 ml-3">{{ $t('Найдено совпадений') }} <span class="text-black-50"> - {{ results.length }}</span>
        </h5>
        <FullHeightBlock class="custom-scroll">
            <div v-show="results.length > 0" class="col-12">
                <table class="table table-bordered bg-light mb-5 mx-auto">
                    <thead class="thead-dark">
                    <tr>
                        <th scope="col">{{ $t('EBS ID') }}</th>
                        <th scope="col">{{ $t('Торговая марка') }}</th>
                        <th scope="col">{{ $t('Область') }}</th>
                        <th scope="col">{{ $t('Район') }}</th>
                        <th scope="col">{{ $t('Населенный пункт') }}</th>
                        <th scope="col">{{ $t('Действия') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item , index) in results" :key="index">
                        <td scope="row">{{ item.id }}</td>
                        <td>
                            <img :src="trademarkData(item).icon"
                                 style="width: 25px; height: 25px; object-fit: contain; margin-right: .75rem;"/>
                            <strong class="text-black-50">{{ trademarkData(item).name }}</strong>
                        </td>
                        <td>{{ item.region_name }}</td>
                        <td>{{ item.area_name }}</td>
                        <td>
                            <a href="#"
                               @click.prevent="$router.push({name: 'Locality', params: {locality_id: item.id} })"
                               class="font-weight-bold">
                                {{ item.locality_name }}
                            </a>
                        </td>
                        <td style="width: 200px;">
                            <button @click="$router.push({name: 'Locality', params: {locality_id: item.id} })"
                                    class="btn btn-sm btn-block btn-primary">
                                {{ $t('Тарифы') }}
                                <i class="ml-2 fas fa-chevron-right"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-show="results.length === 0" class="col-12 text-center">
                <span class="display-4 text-black-50 center-page">{{ $t('Ничего не нашлось ') }} :(</span>
            </div>
        </FullHeightBlock>
    </div>
</template>

<script>

import {chain} from "lodash";
import Helper from "@/Helper";
import {mapState} from "vuex";
import FullHeightBlock from "@/components/FullHeightBlock.vue";

export default {
    name: "LocalitiesSerp",
    components: {
        FullHeightBlock,
    },
    props: {
        localities: {
            type: Array,
            required: true
        },
        search: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            scrollSettings: {
                suppressScrollY: false,
                suppressScrollX: false,
                wheelPropagation: true
            }
        }
    },
    methods: {
        trademarkData(item) {
            return this.trademarks?.find(trademark => {
                return item.trademark_id === trademark.id;
            }) || {
                name: this.$t('Нет данных'),
                icon: require("../assets/question.png")
            };
        }
    },
    computed: {
        ...mapState({
            trademarks: state => state.trademarks,
        }),
        results() {
            const query = Helper.keyboardConverter(this.search.toLowerCase());

            const filtered = this.localities.filter(item => {
                return item.locality_search_name.toLowerCase().includes(query);
            });

            return chain(filtered)
                .sortBy("locality_name")
                .take(30)
                .value();
        },
    }
}
</script>

<style lang="less" scoped>

.items {
    height: calc(100vh - 250px);
    overflow-y: auto;
    border-right: 1px solid #d0d0d0;
    padding-right: 2rem;

    .item {
        position: relative;
        cursor: pointer;
        width: 100%;
        height: 48px;
        padding: .7rem 1rem;
        background: #fff;
        margin-bottom: .5rem;
        border-radius: .5rem;

        &:hover {
            background: #f5f5f5;
        }

        &.active {
            background: #3495E3;
            color: #fff;

            i {
                color: #fff;
            }

            img {
                background: #fff;
                border-radius: .5rem;
            }
        }

        i {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            color: #D0D0D0;
        }

        img {
            padding: .2rem;
            width: 28px;
            height: 28px;
            margin-right: .5rem;
        }
    }
}

.center-page {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>
