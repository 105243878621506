var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar',[_c('div',{staticClass:"row w-75"},[_c('div',{staticClass:"col-5"},[_c('h4',{staticClass:"text-light mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Сообщения'))+" "),_c('button',{staticClass:"ml-3 btn btn-primary",on:{"click":_vm.showSendSmsMessageModal}},[_vm._v(" "+_vm._s(_vm.$t('Отправить'))+" ")])])])])]),_c('div',{staticClass:"container-fluid py-4"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('ul',{staticClass:"app-nav-tabs nav nav-tabs mb-3"},[_c('li',{staticClass:"nav-item active"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('Сообщения')))])]),_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({name: 'SmsSample'})}}},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('Шаблоны сообщений')))])])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('date-range-picker',{class:{ 'date-picker-default-label' : !_vm.dateRange.startDate && !_vm.dateRange.endDate },attrs:{"locale-data":_vm.localDate,"opens":"left","auto-apply":true,"ranges":false,"maxDate":(new Date()).toISOString(),"linked-calendars":false,"single-date-picker":"range"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),(_vm.dateRange.startDate && _vm.dateRange.endDate)?_c('button',{staticClass:"btn btn-danger ml-3",on:{"click":function($event){return _vm.resetDateRange()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]):_vm._e()],1)]),_c('FullHeightBlock',{staticClass:"custom-scroll"},[(_vm.sms_messages.length || _vm.is_init)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.sms_messages,"mode":"remote","totalRows":_vm.total,"isLoading":_vm.isLoading,"pagination-options":{
                        enabled: true,
                        perPageDropdown: [10, 20, 50, 100],
                        dropdownAllowAll: false,
                        nextLabel: _vm.$t('туда'),
                        prevLabel: _vm.$t('сюда'),
                        ofLabel: _vm.$t('из'),
                        rowsPerPageLabel: _vm.$t('Записей на страницу'),
                    }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'message')?_c('div',[_c('small',{staticClass:"alert alert-secondary d-block mb-0",attrs:{"data-toggle":"tooltip","title":props.row.message}},[_vm._v(" "+_vm._s(_vm.stringLimit(props.row.message, 50))+" ")])]):(props.column.field === 'phones')?_c('div',[(Math.random())?_c('div',{staticClass:"text-success",attrs:{"data-toggle":"tooltip","title":props.row.phones.join('<br>')}},[_vm._v(" "+_vm._s(_vm.$t('получателей'))+": "+_vm._s(props.row.phones.length)+" ")]):_vm._e()]):(props.column.field === 'user')?_c('div',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(props.row.user ? props.row.user.name : '-')+" ")]):(props.column.field === 'result')?_c('div',{staticClass:"text-danger",attrs:{"data-toggle":"tooltip","title":_vm.getFailedPhones(props.row.result).join('<br>')}},[_vm._v(" "+_vm._s(_vm.$t('ошибок'))+": "+_vm._s(_vm.getFailedPhones(props.row.result).length)+" ")]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,1042451359)})],1)]):_c('div',{staticClass:"centered"},[_c('div',{staticClass:"display-4 text-black-50 mb-4"},[_vm._v(_vm._s(_vm.$t('Тут пока ничего нет')))])])])],1),_c('SendSmsMessageModal',{on:{"messageSent":_vm.onMessageSent}}),_c('SendNotifierMessageModal',{on:{"messageSent":_vm.onMessageSent}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }