<template>
    <div>
        <Navbar>
            <div class="row">
                <div class="col-12">
                    <h4 class="text-light mb-0">{{ $t('Пользователи') }}
                        <button @click="create()" class="ml-3 btn btn-primary">{{ $t('Добавить') }}</button>
                    </h4>
                </div>
            </div>
        </Navbar>
        <FullHeightBlock class="custom-scroll">
            <div class="container-fluid pt-4">
                <div v-if="users.length" class="row">
                    <div class="col-12">
                        <vue-good-table
                            :columns="columns"
                            :rows="users"
                            :pagination-options="{
                            enabled: true,
                            perPage: 10,
                            perPageDropdown: [5, 10, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'role'">
                                    {{ props.row.role.name }}
                                </div>
                                <div v-else-if="props.column.field === 'email'">
                                <span class="text-black-50 font-weight-bold">
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                                </div>
                                <div v-else-if="props.column.field === 'department'">
                                <span class="text-info font-weight-bold">
                                    {{ props.formattedRow[props.column.field]?.name || $t('Не указано') }}
                                </span>
                                </div>
                                <div v-else-if="props.column.field === 'phones'">
                                    <div v-if="props.formattedRow[props.column.field].length" class="card p-3 bg-light">
                                        <small v-for="item in props.formattedRow[props.column.field]" :key="item.id">
                                            {{ userPhoneTypes[item.type] }} : <span class="font-weight-bold">{{
                                                item.phone
                                            }}</span>
                                        </small>
                                    </div>
                                </div>
                                <div v-else-if="props.column.field === 'created_at'">
                                <span class="d-block">
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                                    <small v-if="is('administrator')" class="d-block">
                                        {{ $t("Активность: ") }} <strong>{{
                                            formatDate(props.row.last_activity_at)
                                        }}</strong>
                                    </small>
                                </div>
                                <div v-else-if="props.column.field === 'action'">
                                    <div class="btn-group btn-block">
                                        <button @click="update(props.row)" class="btn btn-sm btn-outline-primary">
                                            <i class="fas fa-user-edit fa-fw"></i>
                                            {{ $t('Править') }}
                                        </button>
                                        <button v-if="props.row.id !== $store.state.user.id"
                                                @click="destroy(props.row.id)"
                                                class="btn btn-sm btn-outline-danger">
                                            <i class="fas fa-user-times fa-fw"></i>
                                            {{ $t('Удалить') }}
                                        </button>
                                    </div>
                                </div>
                                <div v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
                <div v-else class="centered">
                    <div class="display-4 text-black-50">{{ $t('Тут пока ничего нет') }}</div>
                </div>
            </div>
        </FullHeightBlock>
        <CreateUserModal
            @created="fetch(false)">
        </CreateUserModal>
        <UpdateUserModal
            @changePhone="fetch(false)"
            @updated="updateUser">
        </UpdateUserModal>
    </div>
</template>

<script>

import UpdateUserModal from "@/modals/UpdateUserModal";
import Navbar from "@/components/Navbar";
import UserService from "@/services/UserService";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import {EventBus, Events} from "@/EventBus";
import CreateUserModal from "@/modals/CreateUserModal";
import i18n from "@/i18n";
import moment from "moment-mini";
import TimeAgo from 'javascript-time-ago';
import ru from 'javascript-time-ago/locale/ru'
import uk from 'javascript-time-ago/locale/uk'
import en from 'javascript-time-ago/locale/en'
import bg from 'javascript-time-ago/locale/bg'
import FullHeightBlock from "@/components/FullHeightBlock.vue";

const User = new UserService();

export const userPhoneTypes = {
    work: i18n.t("Рабочий"),
    home: i18n.t("Личный"),
    inside: i18n.t("Внутренний"),
}

export default {
    name: 'Users',
    components: {
        FullHeightBlock,
        Navbar,
        VueGoodTable,
        UpdateUserModal,
        CreateUserModal
    },
    data() {
        return {
            userPhoneTypes,
            users: [],
            columns: [
                {
                    label: 'ID',
                    field: 'id',
                    type: 'number'
                },
                {
                    label: this.$tc('Имя'),
                    field: 'name',
                },
                {
                    label: this.$tc('Email'),
                    field: 'email',
                },
                {
                    label: this.$tc('Роль'),
                    field: 'role',
                },
                {
                    label: this.$tc('Департамент'),
                    field: 'department',
                },
                {
                    label: this.$tc('Телефон'),
                    field: 'phones',
                },
                {
                    label: this.$tc('Дата регистрации'),
                    field: 'created_at',
                    formatFn: value => {
                        return value ? moment(value).format("YYYY-MM-DD HH:mm") : null;
                    }
                },
                {
                    label: this.$tc('Действия'),
                    field: 'action',
                    width: '250px'
                },
            ],
        }
    },
    methods: {
        create() {
            EventBus.$emit(Events.CREATE_USER_MODAL_OPEN);
        },
        update(user) {
            EventBus.$emit(Events.UPDATE_USER_MODAL_OPEN, user);
        },
        async destroy(id) {
            if (!confirm(this.$tc("Удалить юзера?"))) {
                return;
            }

            EventBus.$emit(Events.SHOW_PRELOADER);
            const {code, body} = await User.delete(id);
            EventBus.$emit(Events.HIDE_PRELOADER);

            if (code === 200) {
                this.users = this.users.filter(user => user.id !== id)
                this.$toast.success(this.$tc('Юзер удален'));
            } else {
                this.$toast.error(body.message);
            }
        },
        updateUser(user) {
            this.users = this.users.map(item => {
                return item.id === user.id ? user : item;
            });
        },
        async fetch(preloader = true) {
            preloader && this.$preloader.show();
            const {code, body} = await User.fetch();
            preloader && this.$preloader.hide();

            if (code === 401) {
                await this.$router.push({name: 'Auth'})
            } else {
                this.users = body;
            }
        },
        formatDate(date) {
            if (!date) {
                return this.$t("давно");
            }

            const timeAgo = new TimeAgo(this.$i18n.locale)
            return timeAgo.format(new Date(date));
        },
    },
    created() {
        TimeAgo.addLocale(en);
        TimeAgo.addLocale(uk);
        TimeAgo.addLocale(ru);
        TimeAgo.addLocale(bg);
    },
    async mounted() {
        document.title = this.$tc("Пользователи");
        await this.fetch();
    }
}
</script>
