<template>
    <div>
        <Preloader/>
        <component v-if="isInit" :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>

import AuthService from "@/services/AuthService";
import Preloader from "@/components/Preloader";
import LocalityService from "@/services/LocalityService";
import MessageService from "@/services/MessageService";
import UserRoleService from "@/services/UserRoleService";
import {EventBus, Events} from "@/EventBus";
import {debounce} from "lodash";
import DefaultLayout from "@/layoyts/DefaultLayout";
import {mutationTypes} from "@/store";
import {mapState} from "vuex";
import DepartmentService from "@/services/DepartmentService";

const Auth = new AuthService();
const Locality = new LocalityService();
const Message = new MessageService();
const UserRole = new UserRoleService();
const Department = new DepartmentService()

export default {
    name: 'App',
    components: {
        Preloader,
        DefaultLayout
    },
    data() {
        return {
            isInit: false
        }
    },
    methods: {
        observeDOM() {
            const observer = new MutationObserver(debounce(() => {
                EventBus.$emit(Events.DOM_CHANGE);
            }, 500));

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        },
        async initApp() {
            if (this.$route.name === "Auth") {
                this.isInit = true;
                return;
            }

            this.$preloader.show(this.$preloader.DISABLED_ANIMATION);

            const {code, body} = await Auth.me();

            if (code !== 200) {
                // запоминаем роут куда юзер ломился без авторизации
                this.$store.commit(mutationTypes.SET_AFTER_AUTH_PATH, this.$router.currentRoute.path);
                this.isInit = true;
                await this.$router.push({name: "Auth"});
            } else {
                this.$store.commit(mutationTypes.SET_USER, body);

                if (window.gtag) {
                    gtag('config', 'GA_TRACKING_ID', {
                        'user_id': body.id
                    });
                }

                Promise.all([
                    Locality.fetch(1),
                    Message.countUnreadMessages(1),
                    UserRole.fetch(1),
                    Department.fetch(1)
                ]).then(resolved => {
                    if (resolved[0].code === 200) {
                        this.$store.commit(mutationTypes.SET_LOCALITIES, resolved[0].body);
                    } else {
                        this.$toast.error(this.$tc("Ошибка загрузки населенных пунктов"));
                    }

                    if (resolved[1].code === 200) {
                        this.$store.commit(mutationTypes.SET_COUNT_UNREAD_MESSAGES, resolved[1].body);
                    } else {
                        this.$toast.error(this.$tc("Ошибка загрузки непрочитанных сообщений"));
                    }

                    if (resolved[2].code === 200) {
                        this.$store.commit(mutationTypes.SET_ROLES_LIST, resolved[2].body);
                    } else {
                        this.$toast.error(this.$tc("Ошибка загрузки типов пользователей"));
                    }

                    if (resolved[3].code === 200) {
                        this.$store.commit(mutationTypes.SET_DEPARTMENTS, resolved[3].body);

                        if (this.currentDepartment === "not_set") {
                            const userDepartment = this.departments
                                .find(item => item.id === this.userDepartmentId) || null;

                            if (this.visibleDepartmentsSlug.includes(userDepartment.slug)) {
                                this.$store.commit(mutationTypes.SET_CURRENT_DEPARTMENT, userDepartment);
                            } else {
                                this.$store.commit(mutationTypes.SET_CURRENT_DEPARTMENT, null);
                            }
                        }
                    } else {
                        this.$toast.error(this.$tc("Ошибка загрузки департаментов"));
                    }

                    // если юзер ломился куда то без авторизации - редиректим его туда
                    if (this.afterAuthPath) {
                        this.$router.push({path: this.afterAuthPath});
                        this.$store.commit(mutationTypes.SET_AFTER_AUTH_PATH, null);
                    }

                    this.$preloader.hide();
                }).catch(err => {
                    this.$preloader.hide();
                }).finally(() => {
                    this.isInit = true;
                })
            }
        },
        async handleEbsAuth() {
            const url = new URL(window.location.href);

            if (url.searchParams.has('token')) {
                const token = url.searchParams.get('token');

                // удаляем токен из URL
                url.searchParams.delete('token');
                window.history.replaceState({}, document.title, url.toString());

                const r = await Auth.authByEbsToken(token);

                if (r.code === 200 || r.code === 201) {
                    this.$store.commit(mutationTypes.SET_BEARER_TOKEN, r.body.access_token);
                } else {
                    this.$toast.error(this.$tc("Не верный токен доступа"));
                }
            }
        }
    },
    computed: {
        ...mapState({
            appLanguage: state => state.appLanguage,
            afterAuthPath: state => state.afterAuthPath,
            departments: state => state.departments,
            currentDepartment: state => state.currentDepartment,
            user: state => state.user,
            visibleDepartmentsSlug: state => state.visibleDepartmentsSlug
        }),
        layout() {
            return this.$route.meta.layout || DefaultLayout;
        }
    },
    watch: {
        appLanguage: {
            immediate: true,
            handler(val) {
                this.$i18n.locale = val;
            }
        },
        $route: {
            immediate: true,
            handler(to) {
                if (/^[a-zA-Z]{2}$/.test(to.query?.lang)) {
                    this.$store.commit(mutationTypes.SET_APP_LANGUAGE, to.query.lang.toLowerCase());
                    this.$router.replace({"query": null});
                }
            }
        }
    },
    created() {
        document.addEventListener('keydown', e => {
            if ((e.ctrlKey || e.keyCode === 17)
                && !this.$store.state.shiftKeyPressed
                && this.is('administrator')
            ) {
                this.$store.commit(mutationTypes.SET_SHIFT_KEY_PRESSED, true);
            }
        });

        document.addEventListener('keyup', e => {
            if ((e.ctrlKey || e.keyCode === 17)
                && this.$store.state.shiftKeyPressed
                && this.is('administrator')
            ) {
                this.$store.commit(mutationTypes.SET_SHIFT_KEY_PRESSED, false);
            }
        });
    },
    async mounted() {
        document.querySelector("link[rel='icon']").href = require("@/assets/logo.png");

        this.observeDOM();

        const bodyEl = $('body');

        bodyEl.tooltip({
            selector: '[data-toggle="tooltip"]',
            html: true
        });

        bodyEl.popover({
            selector: '[data-toggle="popover"]',
            container: 'body',
            trigger: 'focus',
            html: true
        });

        this.$router.afterEach(() => {
            $('[data-toggle="tooltip"]').tooltip("hide");
            $('.modal').modal('hide');
        });

        await this.handleEbsAuth();
        await this.initApp();
    }
}
</script>


<style lang="less">
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./styles/styles";

html {
    font-size: 15px;

    @media (max-width: 1440px) {
        font-size: 14px;
    }

    @media (max-width: 767px) {
        font-size: 12px;

        .content {
            width: calc(80vw - 10px);
        }
    }
}

body {
    background: #e5e5e5;
    min-height: 100vh;
}

.animate__animated {
    animation-duration: 0.5s !important;
}

.border-bottom, .border-left {
    border-color: #D0D0D0 !important;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}

.custom-scroll {
    .scroll-container(#c1c1c1, #e5e5e5);
}

.popover {
    min-width: 400px;
    max-height: 550px;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
}


</style>
