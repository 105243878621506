<template>
    <div class="card p-4">
        <div class="row mb-3">
            <div class="col-6 col-lg-3 mb-2" v-if="computedTrademarks.length">
                <multiselect v-model="selectedTrademark"
                             :options="computedTrademarks"
                             :multiple="true"
                             :searchable="true"
                             :close-on-select="true"
                             :show-labels="false"
                             track-by="value"
                             label="label"
                             :placeholder="$t('Торговая марка')">
                </multiselect>
            </div>
            <div class="col-6 col-lg-3 mb-2">
                <multiselect v-model="selectedRegion"
                             :options="computedRegions"
                             :multiple="true"
                             :searchable="true"
                             :close-on-select="true"
                             track-by="value"
                             label="label"
                             :show-labels="false"
                             :placeholder="$t('Область')">
                </multiselect>
            </div>
            <div class="col-6 col-lg-3 mb-2">
                <multiselect v-model="selectedArea"
                             :options="computedAreas"
                             :disabled="!selectedRegion.length"
                             :multiple="true"
                             :searchable="true"
                             :close-on-select="true"
                             :show-labels="false"
                             track-by="value"
                             label="label"
                             :placeholder="$t('Район')">
                </multiselect>
            </div>
            <div class="col-6 col-lg-3 mb-2">
                <multiselect v-model="selectedLocality"
                             :options="computedLocalities"
                             :disabled="!selectedArea.length"
                             :multiple="true"
                             :searchable="true"
                             :close-on-select="true"
                             :show-labels="false"
                             track-by="value"
                             label="label"
                             :placeholder="$t('Населённый пункт')">
                </multiselect>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center">
            <button class="btn btn-secondary mr-2" @click="reset">{{ $t('Сбросить') }}</button>
            <button class="btn btn-primary" @click="applyFilter">{{ $t('Применить фильтр') }}</button>
        </div>
    </div>
</template>

<script>
import {chain} from "lodash";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
    name: "TariffNoteFilter",
    components: {
        Multiselect
    },
    props: {
        cached_localities: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            selectedTrademark: [],
            selectedRegion: [],
            selectedArea: [],
            selectedLocality: [],
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale;
        },
        computedTrademarks() {
            return chain(this.cached_localities)
                .map("trademark_id")
                .uniq()
                .map(id => {
                    const trademark = this.$store.state.trademarks?.find(item => item.id === id);
                    return {
                        value: id,
                        label: trademark ? trademark.name : this.$t('Нет данных')
                    };
                })
                .value();
        },
        computedTrademarkIds() {
            return this.computedTrademarks.map(trademark => trademark.value);
        },
        computedRegions() {
            return chain(this.cached_localities)
                .filter(item => this.computedTrademarkIds.includes(item.trademark_id))
                .uniqBy("region_name_uk")
                .sortBy("region_name_uk")
                .map(item => {
                    return {
                        label: item[`region_name_${this.locale}`],
                        value: item.region_name_uk
                    }
                })
                .value()
        },
        computedAreas() {
            return chain(this.cached_localities)
                .filter(item => {
                    return this.selectedRegion.find(i => i.value === item.region_name_uk) &&
                        this.computedTrademarkIds.includes(item.trademark_id);
                })
                .uniqBy("area_name_uk")
                .sortBy("area_name_uk")
                .map(item => {
                    return {
                        label: item[`area_name_${this.locale}`],
                        value: item.area_name_uk
                    }
                })
                .value()
        },
        computedLocalities() {
            return chain(this.cached_localities)
                .filter(item => {
                    return this.selectedRegion.find(i => i.value === item.region_name_uk)
                        && this.selectedArea.find(i => i.value === item.area_name_uk);
                })
                .sortBy("locality_name_uk")
                .map(item => {
                    return {
                        label: item[`locality_name_${this.locale}`],
                        value: item.locality_name_uk
                    }
                })
                .value()
        }
    },
    methods: {
        reset() {
            this.selectedTrademark = [];
            this.selectedRegion = [];
            this.selectedArea = [];
            this.selectedLocality = [];
            this.$emit('onFilterReset');
            this.applyFilter();
        },
        applyFilter() {
            this.$emit('onFilter', {
                trademark_id: this.selectedTrademark.map(item => item.value),
                locality_uk: this.selectedLocality.map(item => item.value),
                area_uk: this.selectedArea.map(item => item.value),
                region_uk: this.selectedRegion.map(item => item.value),
            })
        }
    },
    watch: {
        selectedTrademark: {
            deep: true,
            handler() {
                this.selectedRegion = [];
                this.selectedArea = [];
                this.selectedLocality = [];
            }
        },
        selectedRegion() {
            this.selectedArea = [];
            this.selectedLocality = [];
        },
        selectedArea() {
            this.selectedLocality = [];
        },
    }
}
</script>

<style scoped lang="less">

</style>
