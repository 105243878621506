<template>
    <div>
        <Navbar>
            <div class="row">
                <div class="col-12">
                    <h4 class="text-light mb-0">{{ $t('Департаменты') }}
                        <button @click="create()" class="ml-3 btn btn-primary">{{ $t('Добавить') }}</button>
                    </h4>
                </div>
            </div>
        </Navbar>
        <FullHeightBlock class="custom-scroll">
            <div class="container-fluid pt-4">
                <div v-if="departments.length" class="row">
                    <div class="col-12">
                        <vue-good-table
                            :columns="columns"
                            :rows="departments"
                            :pagination-options="{
                            enabled: true,
                            perPage: 10,
                            perPageDropdown: [5, 10, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'slug'">
                                    {{ props.row.slug }}
                                </div>
                                <div v-else-if="props.column.field === 'name'">
                                    {{ props.row.name }}
                                </div>
                                <div v-else-if="props.column.field === 'comment'">
                                    {{ props.row.comment }}
                                </div>
                                <div v-else-if="props.column.field === 'action'">
                                    <div class="btn-group btn-block">
                                        <button @click="update(props.row)" class="btn btn-sm btn-outline-primary">
                                            <i class="fas fa-department-edit fa-fw"></i>
                                            {{ $t('Править') }}
                                        </button>
                                        <button @click="destroy(props.row.id)" class="btn btn-sm btn-outline-danger">
                                            <i class="fas fa-department-times fa-fw"></i>
                                            {{ $t('Удалить') }}
                                        </button>
                                    </div>
                                </div>
                                <div v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
                <div v-else class="centered">
                    <div class="display-4 text-black-50">{{ $t('Тут пока ничего нет') }}</div>
                </div>
            </div>
        </FullHeightBlock>
        <CreateDepartmentModal
            @created="fetch(false)">
        </CreateDepartmentModal>
        <UpdateDepartmentModal
            @changePhone="fetch(false)"
            @created="(department) => departments.unshift(department)"
            @updated="updateDepartment">
        </UpdateDepartmentModal>
    </div>
</template>

<script>

import UpdateDepartmentModal from "@/modals/UpdateDepartmentModal";
import Navbar from "@/components/Navbar";
import DepartmentService from "@/services/DepartmentService";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import {EventBus, Events} from "@/EventBus";
import CreateDepartmentModal from "@/modals/CreateDepartmentModal";
import i18n from "@/i18n";
import FullHeightBlock from "@/components/FullHeightBlock.vue";

const Department = new DepartmentService();

export const departmentPhoneTypes = {
    work: i18n.t("Рабочий"),
    home: i18n.t("Личный"),
    inside: i18n.t("Внутренний"),
}

export default {
    name: 'Departments',
    components: {
        FullHeightBlock,
        Navbar,
        VueGoodTable,
        UpdateDepartmentModal,
        CreateDepartmentModal
    },
    data() {
        return {
            departmentPhoneTypes,
            departments: [],
            columns: [
                {
                    label: 'ID',
                    field: 'id',
                    type: 'number'
                },
                {
                    label: this.$tc('Слаг'),
                    field: 'slug',
                },
                {
                    label: this.$tc('Имя'),
                    field: 'name',
                },
                {
                    label: this.$tc('Комментарий'),
                    field: 'comment',
                },
                {
                    label: this.$tc('Пользователей'),
                    field: 'users_count',
                },
                {
                    label: this.$tc('Действия'),
                    field: 'action',
                    width: '250px'
                },
            ],
        }
    },
    methods: {
        create() {
            EventBus.$emit(Events.CREATE_DEPARTMENT_MODAL_OPEN);
        },
        update(department) {
            EventBus.$emit(Events.UPDATE_DEPARTMENT_MODAL_OPEN, department);
        },
        async destroy(id) {
            if (!confirm(this.$tc("Удалить департамент?"))) {
                return;
            }

            EventBus.$emit(Events.SHOW_PRELOADER);
            const {code, body} = await Department.delete(id);
            EventBus.$emit(Events.HIDE_PRELOADER);

            if (code === 200) {
                this.departments = this.departments?.filter(item => item.id !== id)

                this.$toast.success(this.$tc('Департамент удален'));
            } else {
                this.$toast.error(body.message || this.$tc('Ошибка удаления!'));
            }
        },
        updateDepartment(department) {
            this.departments = this.departments?.map(item => {
                if (item.id === department.id) {
                    item = department;
                }

                return item;
            });
        },
        async fetch(preloader = true) {
            preloader && this.$preloader.show();
            const {code, body} = await Department.fetch();
            preloader && this.$preloader.hide();

            if (code === 401) {
                await this.$router.push({name: 'Auth'})
            } else {
                this.departments = body;
            }
        }
    },
    async mounted() {
        document.title = this.$tc("Департаменты");
        await this.fetch();
    }
}
</script>
