<template>
    <div>
        <Navbar>
            <div class="row w-75">
                <div class="col-5">
                    <h4 class="text-light mb-0">
                        {{ $t('Шаблоны сообщений') }}
                        <button v-if="is('administrator')"
                                @click="showCreateSmsSampleModal()"
                                class="ml-3 btn btn-primary">
                            {{ $t('Добавить') }}
                        </button>
                    </h4>
                </div>
            </div>
        </Navbar>
        <div class="container-fluid py-4">
            <ul class="app-nav-tabs nav nav-tabs mb-3">
                <li @click.prevent="$router.push({name: 'SmsMessage'})" class="nav-item">
                    <a class="nav-link" href="#">{{ $t('СМС сообщения') }}</a>
                </li>
                <li class="nav-item active">
                    <a class="nav-link" href="#">{{ $t('Шаблоны сообщений') }}</a>
                </li>
            </ul>
            <FullHeightBlock class="custom-scroll">
                <div v-if="sms_samples.length || is_init" class="row">
                    <div class="col-12">
                        <vue-good-table
                            :columns="columns"
                            :rows="sms_samples"
                            mode="remote"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="total"
                            :isLoading.sync="isLoading"
                            :pagination-options="{
                            enabled: true,
                            perPageDropdown: [10, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'message'">
                                    <small class="alert alert-secondary d-block mb-0"
                                           data-toggle="tooltip"
                                           :title="props.row.message">
                                        {{ stringLimit(props.row.message, 50) }}
                                    </small>
                                </div>
                                <div v-else-if="props.column.field === 'comment'">
                                    <small class="alert alert-primary d-block mb-0"
                                           v-if="props.row.comment"
                                           data-toggle="tooltip"
                                           :title="props.row.comment">
                                        {{ stringLimit(props.row.comment, 50) }}
                                    </small>
                                </div>
                                <div v-else-if="props.column.field === 'user'"
                                     class="text-primary">
                                    {{ props.row.user ? props.row.user.name : '-' }}
                                </div>
                                <div v-else-if="props.column.field === 'action'">
                                    <div class="btn-group btn-block">
                                        <button @click="showUpdateSmsSampleModal(props.row)"
                                                class="btn btn-sm btn-outline-primary">
                                            <i class="fas fa-edit"></i>
                                            {{ $t('Редактировать') }}
                                        </button>
                                        <button @click="remove(props.row.id)"
                                                class="btn btn-sm btn-outline-danger">
                                            <i class="fas fa-trash"></i>
                                            {{ $t('Удалить') }}
                                        </button>
                                    </div>
                                </div>
                                <div v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
                <div v-else class="centered">
                    <div class="display-4 text-black-50 mb-4">{{ $t('Тут пока ничего нет') }}</div>
                </div>
            </FullHeightBlock>
        </div>
        <CreateSmsSampleModal @created="loadSamples()"/>
        <UpdateSmsSampleModal @updated="loadSamples()"/>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import FullHeightBlock from "@/components/FullHeightBlock";
import CreateSmsSampleModal from "@/modals/CreateSmsSampleModal";
import UpdateSmsSampleModal from "@/modals/UpdateSmsSampleModal";
import Helper from "@/Helper";
import {EventBus, Events} from "@/EventBus";
import SmsSampleService from "@/services/SmsSampleService";
import moment from "moment-mini";

const SmsSample = new SmsSampleService();

export default {
    name: 'SmsSample',
    components: {
        Navbar,
        VueGoodTable,
        FullHeightBlock,
        CreateSmsSampleModal,
        UpdateSmsSampleModal
    },
    data() {
        return {
            is_init: false,
            sms_samples: [],
            total: 0,
            limit: 10,
            offset: 0,
            isLoading: false,
            configuration: {},
        }
    },
    methods: {
        async loadSamples() {
            this.offset = 0;
            await this.loadSmsSamples();
        },
        showCreateSmsSampleModal() {
            EventBus.$emit(Events.CREATE_SMS_SAMPLE_MODAL_OPEN)
        },
        showUpdateSmsSampleModal(sample) {
            EventBus.$emit(Events.UPDATE_SMS_SAMPLE_MODAL_OPEN, sample)
        },
        stringLimit: Helper.stringLimit,
        async remove(id) {
            if (!confirm(this.$tc("Удалить?"))) return;

            this.isLoading = true;
            await SmsSample.delete(id);
            await this.loadSmsSamples();
            this.isLoading = false;
        },
        async loadSmsSamples(limit = 10, offset = 0) {
            this.isLoading = true;
            const {code, body} = await SmsSample.fetch(limit, offset);
            this.isLoading = false;

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.sms_samples = body.content;
                this.total = body.total;
            }
        },
        async onPageChange(e) {
            this.offset = (e.currentPage * this.limit) - this.limit;
            await this.loadSmsSamples(this.limit, this.offset);
        },
        async onPerPageChange(e) {
            this.limit = e.currentPerPage;
            await this.loadSmsSamples(this.limit, this.offset);
        },
    },
    computed: {
        columns() {
            return [
                {
                    label: 'ID',
                    field: 'id',
                    sortable: false,
                },
                {
                    label: this.$tc('Сообщение'),
                    field: 'message',
                    sortable: false,
                },
                {
                    label: this.$tc('Комментарий'),
                    field: 'comment',
                    sortable: false,
                },
                {
                    label: this.$tc('Добавил'),
                    field: 'user',
                    sortable: false,
                },
                {
                    label: this.$tc('Дата'),
                    field: 'updated_at',
                    sortable: false,
                    width: "250px",
                    formatFn: value => {
                        return value ? moment(value).format("YYYY-MM-DD HH:mm") : null;
                    }
                },
                {
                    label: this.$tc('Действия'),
                    field: 'action',
                    sortable: false,
                    width: "300px"
                },
            ];
        },
    },
    async mounted() {
        document.title = this.$tc("Шаблоны сообщений");
        this.$preloader.show();
        await this.loadSmsSamples();
        this.$preloader.hide();
        this.is_init = true;
    }
}
</script>

<style lang="less">
</style>
